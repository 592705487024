.ctf-partition {
  &-title {
    font-size: rem(36);
    font-weight: 900;
    margin-bottom: rem(22);
    color: $darken-purple;

    &-description {
      margin-bottom: rem(45);
      color: $purple-gray;
      font-size: rem(18);
      line-height: rem(30);
      max-width: rem(800);

      b {
        font-weight: 900;
        color: $darken-purple;
      }
    }
  }

  &-marker {
    position: relative;
    display: block;
    padding-bottom: 40px !important;
    margin-bottom: rem(50) !important;
    &::after {
      content: '';
      display: block;
      width: 100px;
      height: 15px;
      border-radius: 5px;
      background-color: #ffd300;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &-center {
      &::after {
        left: 50%;
        margin-left: -50px;
      }
    }
  }

  &-title-note {
    text-align: center;
    color: $purple-gray-2;

    p {
      font-size: rem(18);
      line-height: rem(24);
    }
  }

  &-content-inner-wrapper {
    background-color: #f0f2f6;
    padding: rem(15);
    border-radius: 5px;
  }

  &-content-wrapper {
    background-color: rgba($lighten-gray-5, 0.9);
    padding-left: rem(50);
    padding-right: rem(50);
    padding-top: rem(60);
    padding-bottom: rem(40);
    border-radius: 5px;
    overflow: hidden;

    @media (max-width: $sm-max-width) {
      padding-left: rem(20);
      padding-right: rem(20);
      padding-top: rem(30);
      padding-bottom: rem(30);
    }
  }
}
